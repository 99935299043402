import { reactive } from "vue";
import streamSaver from "streamsaver";
import { WritableStream } from "web-streams-ponyfill";
import * as zipStream from "./zip-stream.js";

const fileTracker = reactive({})

export default () => {

  const resetFileTracker = (onlyCompleted) => {
    Object.keys(fileTracker).map(k => {
      if (onlyCompleted && fileTracker[k]){
        if (fileTracker[k].loaded >= fileTracker[k].total) delete fileTracker[k]
      }
      else {
        delete fileTracker[k]
      }
    })
  }

  const initTrackerForName = (name) => {
    fileTracker[name] = { total: 10000000000, loaded: 0}
  }

  const submitDownloadJob = (payload) => {
    const { dowloadJobName, linksToDownload, accessToken, store } = payload;

    if (!linksToDownload || !linksToDownload.length) {
      console.log("No Link provided for download");
      return;
    }

    const alreadyAdded = [];
    const fileStream = streamSaver.createWriteStream(`${dowloadJobName}.zip`);

    const readableZipStream = new ZIP({
      start(ctrl) {
        ctrl.fileTracker = fileTracker
        const __tmp = []
        Object.values(linksToDownload).map((l) => {
          if (l.__parent && !__tmp.includes(l.__parent)) {
            __tmp.push(l.__parent);
            ctrl.enqueue({ name: l.__parent, directory: true });
          }
        })
      },
      async pull(ctrl) {
        // find the link thats not downloaded yet
        let linkKey = Object.keys(linksToDownload).find( (k) => !alreadyAdded.includes(k) )

        // If none is found .. close the ZIP
        if (!linkKey || !linksToDownload[linkKey]) {
          ctrl.close();
          return;
        }

        // Add to the list of downloaded
        alreadyAdded.push(linkKey);
        let { __link, __parent, __name, __authToken, __authLink, __objectId, __real_objectId } = linksToDownload[linkKey];

        try {
          // Get the presigned url if Auth is given
          if (__authToken && (__authLink || __objectId)) {
            let tmpResp
            if (__objectId) tmpResp = await store.dispatch('upload/getDownloadAttachmentURL', { params: { id: __objectId, filename: __name, objectId: __real_objectId } })
            if (__authLink) tmpResp = await store.dispatch('upload/getDownloadAttachmentURLViaUrl', { params: { url: __authLink }})
            __link = tmpResp.presignedURL || tmpResp.presignedUrl
          }

          // Get the url and fetch the stream
          if (!__link) throw new Error(`No link found for ${__name} .. Skipping download`)
          const res = await fetch(__link);

          // Define the name for the file
          let name = `${__parent ? __parent + '/' : ''}${__name}`;

          // Get the size of the content
          const contentEncoding = res.headers.get("content-encoding");
          const contentLength = res.headers.get(
            contentEncoding ? "x-file-size" : "content-length"
          );
          if (contentLength === null) throw Error("Response size header unavailable");
          else {
            // console.log(`: Total Size of ${name} .. ${contentLength}`)
            ctrl.fileTracker[name] = {
              total: parseInt(contentLength, 10),
              loaded: 0,
            };
          }

          // Get the stream
          const stream = () => res.body;

          // Enqueue to the ZIP
          ctrl.enqueue({ stream, name });
        } catch (e) {
          console.log(`Problem in downloading .. ${name} ... ${e}`);
          if (ctrl.fileTracker[name]) { ctrl.fileTracker[name].error = `${e}` }
          ctrl.close()
        }
      },
    })

    // Pipe the streams
    if (WritableStream && readableZipStream.pipeTo) {
      return readableZipStream
        .pipeTo(fileStream)
        .then(() => console.log("Completed ZIP Download"));
    }
  };


  return {
    submitDownloadJob, resetFileTracker, initTrackerForName, fileTracker
  };
};
